// Dependencies
import React, { useState } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Hooks
import useSettings from "../hooks/useSettings"
// Components
import Image from "../components/Image"
import Layout from "../components/Layout"
import Markdown from "../components/Markdown"
import Sections, { SectionContent } from "../components/Sections"
import Store from "../components/Store"

const Lockup = ({ className }) => {

  const { tagline, contact } = useSettings();

  return (
    <div className={className}>
      <Markdown field={tagline} />
      <Markdown className="has-links" field={contact} />
    </div>
  )
}

const PageTemplate = ({ data }) => {

  // Content
  const { title, slug, coverImage, sections } = data.contentfulPage;

  // UX
  const [activeSection, setActiveSection] = useState(0);

  return (
    <Store>
      <Layout sectionsLength={sections.length + 1}>

        <Sections>
          {/* Landing */}
          <div className="section__content intro">
            <div className="section__copy">
              <Lockup className="lockup bottom padx2 p1" />
            </div>
            <div className="section__image">
              <Image {...coverImage} />
            </div>
          </div>

          {/* Page sections */}
          {sections.map((entry, index) => (
            <SectionContent {...entry} index={index} key={`${slug}-section-${index}`} />
          ))}
          {/* Footer */}
          <div className="section__content bg-red">
            <div className="section__copy">
              <Lockup className="lockup top padx2 p1" />
            </div>
            <div className="section__image map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.3007335535094!2d-0.2656539840164784!3d51.52604371716045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487611cfff960f63%3A0x9f57649fce2b9d00!2sExcelsior%20Studios!5e0!3m2!1sen!2suk!4v1593533365534!5m2!1sen!2suk" width="800" height="600" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
            </div>
          </div>

        </Sections>
      </Layout>
    </Store>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageTemplate

export const PageTemplatePageQuery = graphql`
  query PageTemplateQuery($slug: String!) {
    contentfulPage(slug: {eq: $slug}) {
      title
      slug
      coverImage {
        fluid(maxHeight: 1080) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      sections {
        ...ContentfulPageSectionFragment
      }
    }
  }
`
