// Dependencies
import React, { useState, useEffect, useContext, useRef } from "react";
import { graphql } from "gatsby";
import { useInView } from 'react-intersection-observer'
// import PropTypes from "prop-types";
// Hooks
// import useEventListener from "../hooks/useEventListener";
// Components
import Image from "../components/Image";
import Markdown from "../components/Markdown";
import { StoreContextActions } from "../components/Store";

// function useScrollInView({ threshold = 0 }) {
//
//   const ref = useRef();
//   const [inView, set] = useState(false);
//
//   const handleScroll = () => {
//     const { top, bottom } = ref.current.getBoundingClientRect();
//     const isTop = top <= window.innerHeight * threshold;
//
//     if (isTop) {
//       set(true)
//     } else {
//       set(false)
//     }
//   }
//
//   useEventListener('scroll', handleScroll);
//
//   return [ref, inView]
// }

export const Section = ({ index, children }) => {

  const [setActiveSection] = useContext(StoreContextActions)

  const [ref, inView, entry] = useInView({
    threshold: 0.25,
  });

  useEffect(() => {
    if (inView) {
      setActiveSection(index)
    }
  }, [inView])

  return (
    <section ref={ref}>
      {children}
    </section>
  )
};

export const SectionContent = ({ title, copy, caption, media, overlay, index }) => {

  const isEven = index % 2 ? true : false;

  return (
    <div className={`section__content ${isEven ? 'even' : 'odd'}`}>
      <div className="section__copy has-links">
        <div className="copy-grid padx2">
          {/* {isEven && <div className="span-1"></div>} */}
          {caption && <Markdown field={caption} className="p1 span-1" />}
          {copy && <Markdown field={copy} className="span-2" />}
          {/* {!isEven && <div className="span-1"></div>} */}
        </div>
      </div>
      <div className="section__image">
        {media && (
          <Image {...media[0]} />
        )}
        {overlay && (
          <div className="section__overlay">
            <img src={overlay.file.url} />
          </div>
        )}
      </div>
    </div>
  )
};

const Sections = ({ children }) => {

  return (
    <div className="sections">
      {React.Children.map(children, (child, index) => {

        return (
          <Section index={index}>
            {child}
          </Section>
        )
      })}
    </div>
  )
};

export default Sections

export const ContentfulPageSectionFragment = graphql`
  fragment ContentfulPageSectionFragment on ContentfulPageSection  {
    title
    caption {
      childMarkdownRemark {
        html
      }
    }
    copy {
      childMarkdownRemark {
        html
      }
    }
    media {
      fluid(maxHeight: 1080) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    overlay {
      file {
        url
      }
    }
  }
`
