// Dependencies
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const GIF = (props) => {
  return (
    <div className="gatsby-image-wrapper" style={{
      position: 'relative',
      overflow: 'hidden'
    }}>
      <div style={{
        width: '100%',
        paddingBottom: `${100 / (props.forceAspect || props.fluid.aspectRatio)}%`
      }} />
      <img src={props.file.url} className="gif" alt="" style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center',
        opacity: 1,
        transition: 'opacity 500ms ease 0s',
      }} />
    </div>
  )
}

const Image = (props) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (props.file && props.file.contentType === "image/gif") {
    return <GIF {...props} />
  } else if (props.fluid) {
    return <Img fluid={!props.forceAspect ? props.fluid : { ...props.fluid, aspectRatio: props.forceAspect }} />
  } else {
    return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
  }
}

export default Image
